export namespace Consts {
  export const WHICH_SERVER = (() => {
    if (window.location.href.search('localhost') > 0) return 'DEV';
    if (window.location.href.search('sisdren.click') > 0) return 'HOMOLOG';
    if (window.location.href.search('saisp.br') > 0) return 'PROD';
    return '';
  })();

  export const BASE_URL = (() => {
    switch (WHICH_SERVER) {
      case 'DEV':
        return 'http://localhost:3333';
      case 'HOMOLOG':
        return 'https://homologacao.sisdren.click';
      case 'PROD':
        return 'https://sisdren.saisp.br';
      default:
        return;
    }
  })();

  export const POCKETBASE_URL = (() => {
    switch (WHICH_SERVER) {
      case 'DEV':
        return 'http://localhost:8181/pocketbase';
      case 'HOMOLOG':
        return 'https://pb.sisdren.click';
      case 'PROD':
        return '';
      default:
        return;
    }
  })();

  export const VECTOR_TILES_SERVER_URL = (() => {
    switch (WHICH_SERVER) {
      case 'DEV':
        return 'http://localhost:8181/pg_tileserv';
      case 'HOMOLOG':
        return 'https://vectortiles.sisdren.click';
      case 'PROD':
        return '';
      default:
        return;
    }
  })();

  export const GEOJSON_FEATURES_SERVER_URL = (() => {
    switch (WHICH_SERVER) {
      case 'DEV':
        return 'http://localhost:8181/pg_featureserv';
      case 'HOMOLOG':
        return 'https://featureserv.sisdren.click';
      case 'PROD':
        return '';
      default:
        return;
    }
  })();

  export const API_URL = (() => {
    switch (WHICH_SERVER) {
      case 'DEV':
        return 'http://localhost:6060';
      case 'HOMOLOG':
        return 'https://api.sisdren.click';
      case 'PROD':
        return '';
      default:
        return;
    }
  })();

  export const MAPBOXACCESSTOKEN = (() => {
    switch (WHICH_SERVER) {
      case 'DEV':
        return process.env.MAPBOXACCESSTOKEN;
      case 'HOMOLOG':
        return process.env.MAPBOXACCESSTOKEN_HOMOLOGATION;
      case 'PROD':
        return process.env.MAPBOXACCESSTOKEN_PRODUCTION;
      default:
        return;
    }
  })();

  export const DEFAULT_BASEMAP = 'mapbox://styles/mapbox/light-v11';

  export const SCREEN_LARGER_THAN_MD = (() => {
    return window.matchMedia('(min-width: 768px)').matches;
  })();
}
